import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useToggleable } from '@stores/toggleables';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { mediaQuery } from '@styles/index';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import HeaderUser from '@layout/Header/HeaderUser/HeaderUser';
import Actions from '@layout/Header/Actions';
import Logo from '@comp/Logo/Logo';
import { useIsMobile } from '@stores/AppCommonData';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { getIsLoggedIn } from '@api/selectors/auth';
import useLoginModalExperiment from '@cont/Login/utils/hooks/useLoginModalExperiment';
import LoginComponent from '@cont/Login/LoginComponent';

const Header = styled.header`
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
  background: var(--color-base-1);
  box-shadow: ${({ $hideHeaderShadow }) =>
    !$hideHeaderShadow ? boxShadowGenerator() : 'none'};
  ${mediaQuery.uptoMobile} {
    box-shadow: inset 0px -1px 0px var(--color-shadow);
    display: block;
    width: 100%;
    height: 48px;
  }
`;

const Nav = styled.nav`
  width: 1136px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  ${mediaQuery.uptoTablet} {
    width: 728px;
  }
  ${mediaQuery.uptoMobile} {
    width: 100%;
    padding: 0 16px;
  }
`;

const StyledLogo = styled(Logo)`
  @media (max-width: 47.95em) {
    grid-column: 1/2;
  }
`;

const RightContentWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

function HeaderComponent({ className, showLogin, hideHeaderShadow }) {
  const isMobile = useIsMobile();
  useLoginModalExperiment();
  const { isOpen: showLoginDrawer, closeElement: closeLoginDrawer } =
    useToggleable(LOGIN_DRAWER);
  const isLoggedIn = useMyInfo(getIsLoggedIn);

  const onClose = () => {
    closeLoginDrawer();
  };
  return (
    <>
      <Header className={className} $hideHeaderShadow={!!hideHeaderShadow}>
        <Nav>
          <StyledLogo />
          {isLoggedIn && !isMobile && (
            <RightContentWrapper>
              <HeaderUser excludeOptions={['darkMode']} />
            </RightContentWrapper>
          )}
          {!isLoggedIn && showLogin && (
            <Actions showModal isJoinForFreeCtaExperiment />
          )}
        </Nav>
      </Header>
      {showLogin && (
        <LoginComponent
          drawerProps={{ drawerState: showLoginDrawer, onClose, login: true }}
        />
      )}
    </>
  );
}

HeaderComponent.propTypes = {
  className: PropTypes.string,
  hideHeaderShadow: PropTypes.bool
};
HeaderComponent.defaultProps = {
  className: '',
  hideHeaderShadow: false
};

export default HeaderComponent;
